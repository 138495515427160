<template>
  <div id="app">
    <Komponen />
  </div>
</template>

<script>
import Komponen from './components/Komponen.vue'

export default {
  name: 'App',
  components: {
    Komponen
  }
}
</script>