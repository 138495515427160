<template>
  <div class="container">
    <h1 style="text-align: center;">Minecraft Online Player List</h1>
    <p>Server Time: {{statusOnline ? 'Online ' + serverTime : 'Offline'}}</p>            
    <p>Client Time: {{clientTime}}</p>            
    <table class="table">
      <thead>
        <tr>
          <th scope="col">No</th>
          <th scope="col">Name</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(player, index) in players" :key="player.name">
          <th scope="row">{{index + 1}}</th>
          <td>{{player.name}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from 'axios'

export default {
  name: 'HelloWorld',
  data: function () {
     return {
      serverTime: "",
      clientTime: "",
      statusOnline: false,
      players: [],
     }
  },
  methods: {
    setTime: function() {
      var date = new Date();

      this.clientTime = `${date.getDate().toString().padStart(2, '0')}-${date.getMonth().toString().padStart(2, '0')}-${date.getFullYear().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
    },
    getData: function() {
      var that = this;

      axios.get('https://api.eganoob.serbaonline.id:1323/')
      .then(function (response) {
        that.players = response.data.Players;
        that.serverTime = response.data.Now;

        that.statusOnline = true;
      })
      .catch(function () {
        that.statusOnline = false;
      });
    }
  },
  mounted: function() {
    setInterval(this.setTime, 1000);
    setInterval(this.getData, 1000);
  }
}
</script>